// app/javascript/controllers/table_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["table"];

  connect() {
    this.createSearchInput();
    this.addSortIconsToHeaders();
  }

  createSearchInput() {
    // Create the input element
    const input = document.createElement('input');
    input.type = 'search';
    input.placeholder = 'Search records on screen...';
    input.title = 'If records are paginated (ie not all records are visible) then this field will only search/sort the records you see...';
    input.setAttribute('data-action', 'input->table#filterRows');
    input.setAttribute('class', 'bg-white rounded-md border border-sapphire placeholder-gray-300 w-full md:w-1/3');
    
    // Insert the input at the beginning of the controller's element
    // Check if the input element already exists
    const existingInput = this.element.querySelector('input[type="search"]');
    if (!existingInput) {
      // Insert the input at the beginning of the controller's element
      this.element.insertBefore(input, this.element.firstChild);
    }
  }

  filterRows(event) {
    // Get the input value
    const sText = event.target.value.toLowerCase();
    // determine if the input value is exclusionary
    const isExclusionary = sText.startsWith('-');
    const searchText = isExclusionary ? sText.slice(1) : sText;
    // Get the table within the controller's element
    const table = this.element.querySelector('table');
    // Iterate over all rows in the table body
    const rows = table.querySelectorAll('tbody tr');
    
    // Iterate over all rows in the table body
    if (searchText.length === 0) {
      // If the search text is empty, show all rows
      rows.forEach(row => row.style.display = '');
      return;
    }

    rows.forEach(row => {
      // Remove the leading '-' if isExclusionary
      // Check if any cell in this row contains the search text
      const matches = Array.from(row.cells).some(cell => cell.textContent.toLowerCase().includes(searchText));
      // Show or hide the row based on whether there was a match
      if (isExclusionary) { 
        row.style.display = (matches ? 'none' : '');
       } else {
        row.style.display = (matches ? '' : 'none');
      };
    });
  }

  addSortIconsToHeaders() {
    const table = this.element.querySelector('table');
    const headers = table.querySelectorAll('th');

    headers.forEach((header, index) => {
        // Wrap the existing header text and sorting icons in a container for better control
        const headerText = header.innerText.trim();
        if (headerText.length === 0) return; // Skip empty headers
        
        const sortingSpan = document.createElement('div');
        sortingSpan.classList.add('flex', 'justify-between', 'items-center', 'w-full', 'hover:cursor-pointer', 'group'); // Tailwind classes for flexbox layout
        sortingSpan.innerHTML = `
            <span class="flex-grow">${headerText}</span>
            <span class="flex items-center" data-column-index="${index}">
                <svg class="sort-desc h-5 w-5 font-semibold" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="display: block;"><path d="M5.293 14.707a1 1 0 001.414 0L10 11.414l3.293 3.293a1 1 0 101.414-1.414l-4-4a1 1 0 00-1.414 0l-4 4a1 1 0 000 1.414z"/></svg>
                <svg class="sort-asc  h-5 w-5 font-semibold" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="display: none;"><path d="M14.707 5.293a1 1 0 00-1.414 0L10 8.586 6.707 5.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l4-4a1 1 0 000-1.414z"/></svg>
            </span>`;
        // Clear the current header and append the new content
        header.innerHTML = '';
        header.setAttribute('data-action', 'click->table#sortColumn');
        header.setAttribute('data-column-index', `${index}`);
        header.appendChild(sortingSpan);
    });
  }

  sortColumn(event) {
    // Find out if the click was on an icon or elsewhere within the span.
    const clickedElement = event.target;
    const isAscendingIcon = clickedElement.classList.contains('sort-asc');
    const isDescendingIcon = clickedElement.classList.contains('sort-desc');

    // Get the index of the column clicked for sorting
    const columnIndex = parseInt(event.currentTarget.getAttribute('data-column-index'));
    
    // Determine new sorting direction
    let newSortDirection;
    if (isAscendingIcon) {
        newSortDirection = 'ascending';
    } else if (isDescendingIcon) {
        newSortDirection = 'descending';
    } else {
        // If clicked outside the icons, toggle based on previous state
        newSortDirection = this.sortDirectionValue === 'ascending' ? 'descending' : 'ascending';
    }

    // Find the table and its rows
    const table = this.element.querySelector('table');
    const rowsArray = Array.from(table.querySelectorAll('tbody tr'));

    // Determine if the column contains numeric values
    const isNumericColumn = rowsArray.every(row => {
        const cellValue = row.querySelector(`td:nth-child(${columnIndex + 1})`).innerText.trim();
        return !isNaN(cellValue) && !isNaN(parseFloat(cellValue));
    });

    // Perform the sort
    rowsArray.sort((a, b) => {
        const aValue = a.querySelector(`td:nth-child(${columnIndex + 1})`).innerText.trim();
        const bValue = b.querySelector(`td:nth-child(${columnIndex + 1})`).innerText.trim();

        // Convert to numbers if the column is numeric
        const numA = isNumericColumn ? parseFloat(aValue) : aValue;
        const numB = isNumericColumn ? parseFloat(bValue) : bValue;

        // Sorting for ascending or descending
        if (newSortDirection === 'ascending') {
            return isNumericColumn ? numA - numB : numA.localeCompare(numB);
        } else {
            return isNumericColumn ? numB - numA : bValue.localeCompare(aValue);
        }
    });

    // Re-append rows to tbody in new order
    const tbody = table.querySelector('tbody');
    rowsArray.forEach(row => tbody.appendChild(row));

    // Set the new sort direction
    this.sortDirectionValue = newSortDirection;
    this.sortColumnValue = columnIndex;

    const headers = this.element.querySelectorAll('th');
    headers.forEach((header, index) => {
        // Skip empty headers
        if (header.innerText.trim().length === 0) return;

        const ascIcon = header.querySelector('.sort-asc');
        const descIcon = header.querySelector('.sort-desc');
        
        if (index === columnIndex) {
            // This is the clicked column, update icons to reflect the new sort direction
            if (newSortDirection === 'ascending') {
              ascIcon.classList.remove('text-canary');
              descIcon.classList.remove('invisible','group-hover:visible');
              descIcon.classList.add('text-canary');
              ascIcon.style.display = 'none'; // hide
              descIcon.style.display = 'inline'; // show as default
            } else {
                descIcon.classList.remove('text-canary');
                ascIcon.classList.remove('invisible','group-hover:visible');
                ascIcon.classList.add('text-canary');
                ascIcon.style.display = 'inline'; // show as default
                descIcon.style.display = 'none'; // hide
            }
        } else {
            // For other columns, you might want to reset icons to indicate they're not sorted
            // Remove the 'text-canary' class to signify that they are not the active sort
            ascIcon.classList.remove('text-canary');
            descIcon.classList.remove('text-canary');
            ascIcon.classList.add('invisible','group-hover:visible');
            descIcon.classList.add('invisible','group-hover:visible');
            // Or you can choose to leave them as is, depending on how you want your UI to behave
            // If you want to reset, uncomment the following lines:
            ascIcon.style.display = 'inline'; // Show as default, indicating sortable but not currently sorted
            descIcon.style.display = 'none'; // Hide by default
        }
    });
  }
}